<template>
  <v-main>
    <!-- Main Content -->
    <v-container fluid>
      <!-- Title -->
      <v-row class="heading-bg">
        <v-col cols="2" md="3">
          <v-btn
            fab
            x-small
            class="mx-2 mb-2"
            color="white"
            @click="$router.back()"
          >
            <v-icon light> mdi-arrow-left </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="9" md="3">
          <h3 class="txt-dark">Upload Users</h3>
        </v-col>
      </v-row>
      <!-- /Title -->

      <!-- File Upload Section -->
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-text>
              <v-btn
                :href="`${mediaUrl}samples/user-sample-data.xlsx`"
                target="_blank"
                outlined
              >
                Download Sample Excel
              </v-btn>
              <!-- <v-row class="mx-4 my-2">
                <v-col class="pa-0 mt-4">
                  <h5 class="text-h6" align="left">Select Client</h5>
                  <v-select
                    solo
                    hide-details=""
                    dense
                    rounded
                    v-model="form.site_id"
                    :items="sites"
                    label="Client"
                  ></v-select>
                </v-col>
              </v-row> -->
              <input
                style="margin-top: 10px; margin-left: 10px"
                type="file"
                id="file"
                name="file"
                ref="file"
                accept="application/ms-excel"
              />

              <v-btn @click="truncatePendingDocuments" text
                >Truncate List</v-btn
              >

              <v-alert v-if="message" type="error">{{ message }}</v-alert>

              <v-progress-circular
                v-if="isLoading"
                indeterminate
                color="primary"
              ></v-progress-circular>

              <v-btn
                v-if="!crudUsers.length"
                class="ma-2"
                color="primary"
                @click="handleFileUpload"
              >
                Upload File
              </v-btn>

              <v-btn
                v-if="crudUsers.length"
                class="ma-2"
                color="success"
                @click="processFile"
              >
                Process File
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Table Section -->
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr>
                    <th style="min-width: 70px">Sr. No.</th>
                    <th style="min-width: 200px">Employee NAME</th>
                    <th style="min-width: 200px">EMPL ID</th>
                    <th style="min-width: 200px">EMAIL</th>
                    <th style="min-width: 200px">DOB</th>
                    <th style="min-width: 200px">BLOOD GROUP</th>
                    <th style="min-width: 200px">FATHER NAME</th>
                    <th style="min-width: 200px">MARITAL STATUS</th>
                    <th style="min-width: 200px">MARRIAGE DATE</th>
                    <th style="min-width: 200px">SPOUSE NAME</th>
                    <th style="min-width: 200px">NATIONALITY</th>
                    <th style="min-width: 200px">RESIDENTIAL STATUS</th>
                    <th style="min-width: 200px">PLACE OF BIRTH</th>
                    <th style="min-width: 200px">COUNTRY ORIGIN</th>
                    <th style="min-width: 200px">RELIGION</th>
                    <th style="min-width: 200px">IS INTERNATIONAL EMPLOYEE</th>
                    <th style="min-width: 200px">IS PHYSICALLY CHALLENGED</th>
                    <th style="min-width: 200px">IS DIRECTOR</th>
                    <th style="min-width: 200px">VERIFICATION STATUS</th>
                    <th style="min-width: 200px">VETIFICATION ON</th>
                    <th style="min-width: 200px">AGENCY NAME</th>
                    <th style="min-width: 200px">REMARKS</th>
                    <th style="min-width: 200px">JOINED ON</th>
                    <th style="min-width: 200px">CONFIRMATION DATE</th>
                    <th style="min-width: 200px">EMPLOYEE STATUS</th>
                    <th style="min-width: 200px">PROBATION PERIOD</th>
                    <th style="min-width: 200px">NOTICE PERIOD</th>
                    <th style="min-width: 200px">BANK NAME</th>
                    <th style="min-width: 200px">BANK BRANCH</th>
                    <th style="min-width: 200px">BANK ACC NO</th>
                    <th style="min-width: 200px">BANK IFSC CODE</th>
                    <th style="min-width: 200px">BANK ACC TYPE</th>
                    <th style="min-width: 200px">BANK PAYMENT TYPE</th>
                    <th style="min-width: 200px">NAME ON BANK</th>
                    <th style="min-width: 200px">IS ESI APPICABLE</th>
                    <th style="min-width: 200px">ESI NUMBER</th>
                    <th style="min-width: 200px">IS PF APPLICABLE</th>
                    <th style="min-width: 200px">UAN NO</th>
                    <th style="min-width: 200px">PF NO</th>
                    <th style="min-width: 200px">PF JOINING DATE</th>
                    <th style="min-width: 200px">FAMILY PF NO</th>
                    <th style="min-width: 200px">IS EXISTING MEMBER OF ESP</th>
                    <th style="min-width: 200px">
                      ALLOW EPF EXCESS CONTRIBUTION
                    </th>
                    <th style="min-width: 200px">
                      ALLOW EPS EXCESS CONTRIBUTION
                    </th>
                    <th style="min-width: 200px">IS LWF APPICABLE</th>
                    <th style="min-width: 200px">API TOKEN</th>
                    <th style="min-width: 200px">EMAIL VERIFIED AT</th>
                    <th style="min-width: 200px">MOBILE</th>
                    <th style="min-width: 200px">GENDER</th>
                    <th style="min-width: 200px">GROUP</th>
                    <th style="min-width: 200px">GROUP DIVISION</th>
                    <th style="min-width: 200px">IS RELIEVER</th>
                  </tr>
                </thead>
                <tbody>
                  <loading-table
                    :isLoading="isLoading"
                    cols="2"
                  ></loading-table>
                  <tr
                    v-for="(cruduser, at) in crudUsers"
                    :key="`cruduser${at}`"
                  >
                    <td>{{ serialNoStarting + at + 1 }}</td>
                    <td>{{ cruduser.full_name }}</td>
                    <td>{{ cruduser.employee_id }}</td>
                    <td>{{ cruduser.email }}</td>
                    <td>{{ cruduser.dob }}</td>
                    <td>{{ cruduser.blood_group }}</td>
                    <td>{{ cruduser.father_name }}</td>
                    <td>{{ cruduser.marital_status }}</td>
                    <td>{{ cruduser.marriage_date }}</td>
                    <td>{{ cruduser.spouse_name }}</td>
                    <td>{{ cruduser.nationality }}</td>
                    <td>{{ cruduser.residential_status }}</td>
                    <td>{{ cruduser.place_of_birth }}</td>
                    <td>{{ cruduser.country_origin }}</td>
                    <td>{{ cruduser.religion }}</td>
                    <td>{{ cruduser.is_international_employee }}</td>
                    <td>{{ cruduser.is_physically_challanged }}</td>
                    <td>{{ cruduser.is_director }}</td>
                    <td>{{ cruduser.verification_status }}</td>
                    <td>{{ cruduser.vetification_on }}</td>
                    <td>{{ cruduser.agency_name }}</td>
                    <td>{{ cruduser.remarks }}</td>
                    <td>{{ cruduser.joined_on }}</td>
                    <td>{{ cruduser.confirmation_date }}</td>
                    <td>{{ cruduser.employee_status }}</td>
                    <td>{{ cruduser.probation_period }}</td>
                    <td>{{ cruduser.notice_period }}</td>
                    <td>{{ cruduser.bank_name }}</td>
                    <td>{{ cruduser.bank_branch }}</td>
                    <td>{{ cruduser.bank_acc_no }}</td>
                    <td>{{ cruduser.bank_ifsc_code }}</td>
                    <td>{{ cruduser.bank_acc_type }}</td>
                    <td>{{ cruduser.bank_payment_type }}</td>
                    <td>{{ cruduser.name_on_bank }}</td>
                    <td>{{ cruduser.is_esi_applicable }}</td>
                    <td>{{ cruduser.esi_number }}</td>
                    <td>{{ cruduser.is_pf_applicable }}</td>
                    <td>{{ cruduser.uan_no }}</td>
                    <td>{{ cruduser.pf_no }}</td>
                    <td>{{ cruduser.pf_joining_date }}</td>
                    <td>{{ cruduser.family_pf_no }}</td>
                    <td>{{ cruduser.is_existing_member_of_eps }}</td>
                    <td>{{ cruduser.allow_epf_excess_contribution }}</td>
                    <td>{{ cruduser.allow_eps_excess_contribution }}</td>
                    <td>{{ cruduser.is_lwf_applicable }}</td>
                    <td>{{ cruduser.api_token }}</td>
                    <td>{{ cruduser.email_verified_at }}</td>
                    <td>{{ cruduser.mobile }}</td>
                    <td>{{ cruduser.gender }}</td>
                    <td>{{ cruduser.group }}</td>
                    <td>{{ cruduser.group_division }}</td>
                    <td>{{ cruduser.is_reliever }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- /Main Content -->
  </v-main>
</template>

<script type="text/javascript">
import axios from "axios";
import backbutton from "@/components/backbutton.vue";

export default {
  name: "uploadusers",
  components: { backbutton },
  data() {
    return {
      crudUsers: [],
      message: "",
      sites: [],
      count: 0,
      serialNoStarting: 1,
      isLoading: false,
    };
  },
  mounted() {
    // this.getMasters();
    this.getData();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("users/masters");
      masters = masters.data;

      masters.roles.forEach((role) => {
        this.roleItems.push({
          id: role.id,
          text: role.name,
          value: role.id,
        });
      });

      masters.sites.forEach((site) => {
        this.sites.push({
          id: site.id,
          text: site.name,
          value: site.id,
        });
      });
    },
    async getData(page = 1) {
      this.isLoading = true;
      let crudUsers = await axios.get(`/crude_users`);
      this.crudUsers = crudUsers.data.data;
      // console.log(this.crudUsers);
      this.count = crudUsers.data.count;
      this.serialNoStarting = (page - 1) * this.rowsPerPage;
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isLoading = true;
      this.users = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("usersData", this.users);
      await axios
        .post("upload_user", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.crudUsers = response.data.data;
          this.isLoading = false;
          // alert("Uploaded Successfully");
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
    async processFile() {
      try {
        this.isLoading = true;
        await axios.get("process_user");
        alert("Processed Successfully");
        await axios.get("truncate_users");
        this.crudUsers = [];
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async truncatePendingDocuments() {
      await axios.get("truncate_users");
      alert("Truncated Successfully");
      this.crudUsers = [];
    },
    async deleteDocument($id) {
      var r = confirm("Are you sure you want to delete the document!");
      if (r == true) {
        await axios.delete(`crude_users/${$id}`);
        this.crudUsers = this.crudUsers.filter((d) => d.id != $id);
      }
    },
  },
};
</script>
