<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            :rounded="selectedYear == '' ? 't-xl' : 'xl'"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-toolbar-title> Select year: </v-toolbar-title>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="mb-1" style="max-width: 166px">
                  <v-select
                    solo
                    dense
                    rounded
                    hide-details=""
                    v-model="selectedYear"
                    :items="years"
                    label="Year"
                    @change="getData"
                  ></v-select>
                  <!-- @change="updateCalendar" -->
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-toolbar-title> From Month: </v-toolbar-title>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-3">
                      <v-select
                        solo
                        dense
                        rounded
                        hide-details=""
                        v-model="selectedFromMonth"
                        :items="months"
                        label="From Month"
                        @change="getData"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="pa-0">
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-toolbar-title> To Month: </v-toolbar-title>
                    </v-col>
                  </v-row>
                  <v-row class="ma-0">
                    <v-col class="pa-0 mx-2">
                      <v-select
                        solo
                        dense
                        rounded
                        hide-details=""
                        v-model="selectedToMonth"
                        :items="months"
                        label="To Month"
                        @change="getData"
                      ></v-select>
                      <!-- @change="updateCalendar" -->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-col class="ma-0 mt-4" v-if="monthly_salaries.length == 0">
                <v-row justify="center" class="ma-0">
                  <v-col align="center" class="pa-0">
                    <v-img
                      :src="require('../../assets/voe_imgs/calendar.png')"
                      alt="Logo"
                      width="50"
                      height="50"
                    ></v-img>
                  </v-col>
                </v-row>
                <v-row justify="center" class="ma-0">
                  <v-col align="center" class="pa-0">
                    <span class="blue--text text-h5"
                      >Please select a year to view your salary slips</span
                    >
                  </v-col>
                </v-row>
                <v-row justify="center" class="ma-0">
                  <v-col align="center" class="pa-0">
                    <v-img
                      :src="require('../../assets/voe_imgs/salary-slips.png')"
                      alt="Logo"
                      width="250"
                      height="280"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-else>
                <hr class="mb-2" />

                <v-card
                  class="my-2"
                  rounded="xl"
                  v-for="monthly_salary in monthly_salaries"
                  :key="monthly_salary.id"
                >
                  <v-row class="ma-0" justify="center">
                    <v-col
                      style="max-width: 75px"
                      class="pa-0 my-auto"
                      align="center"
                    >
                      <v-img
                        class="ma-2"
                        :src="require('../../assets/voe_imgs/slip.png')"
                        alt="Logo"
                        width="50"
                        height="50"
                      ></v-img>
                    </v-col>
                    <v-col justify="center" class="my-auto">
                      <span class="text-h6" align="center"
                        >{{ monthly_salary.month_id | mm }}
                        {{ monthly_salary.year }}</span
                      >
                      <br />
                      <span class="text-caption"
                        >Generated on
                        {{ monthly_salary.created_at | DDMMMYYYY }}</span
                      >
                    </v-col>
                    <v-col class="pa-0 my-auto mx-auto">
                      <v-row class="ma-0">
                        <v-col align="center" class="pa-0">
                          <v-img
                            class="mt-3 ma-0"
                            :src="require('../../assets/voe_imgs/save.png')"
                            alt="Logo"
                            width="20"
                            height="20"
                          ></v-img>
                          <span class="text-caption">Save</span>
                        </v-col>
                        <v-col align="center" class="pa-0">
                          <v-img
                            class="mt-3 ma-0"
                            :src="require('../../assets/voe_imgs/share.png')"
                            alt="Logo"
                            width="20"
                            height="20"
                          ></v-img>
                          <span class="text-caption py-2">Share</span>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0">
                        <v-col align="center" class="pa-0">
                          <v-img
                            class="mt-3 ma-0"
                            :src="require('../../assets/voe_imgs/mail.png')"
                            alt="Logo"
                            width="20"
                            height="20"
                          ></v-img>
                          <span class="text-caption">Mail</span>
                        </v-col>
                        <v-col align="center" class="pa-0 mb-2">
                          <v-img
                            class="mt-3 ma-0"
                            :src="require('../../assets/voe_imgs/print.png')"
                            alt="Logo"
                            width="20"
                            height="20"
                          ></v-img>
                          <span class="text-caption">Print</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text" v-if="selectedYear != ''">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      selectedFromMonth: "",
      selectedToMonth: "",
      selectedYear: "",
      monthly_salaries: [],
      months: [
        { text: "January", value: "1" },
        { text: "February", value: "2" },
        { text: "March", value: "3" },
        { text: "April", value: "4" },
        { text: "May", value: "5" },
        { text: "June", value: "6" },
        { text: "July", value: "7" },
        { text: "August", value: "8" },
        { text: "September", value: "9" },
        { text: "October", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" },
      ],
      years: [
        { text: "2022", value: "2022" },
        { text: "2023", value: "2023" },
        { text: "2024", value: "2024" },
      ],
    };
  },

  methods: {
    async getData() {
      this.isLoading = true;
      let monthly_salaries = await axios.get(
        `monthly_salaries?year=${this.selectedYear}&from_month=${this.selectedFromMonth}&to_month=${this.selectedToMonth}&user_id=${this.user.id}`
      );
      this.monthly_salaries = monthly_salaries.data.data;
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.custom-border {
  border-color: #ff0000; /* Replace with your desired border color */
}
</style>
