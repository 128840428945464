<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="mx-4 my-2">
          <v-col class="pa-0 mt-4">
            <h5 class="text-h6" align="left">Name of Scheduler</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.name"
              placeholder="Scheduler Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="update"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `/schedulers/${this.$route.params.schedulerId}`
      );
      this.form = form.data.data;
      this.isLoading = false;
    },
    async update() {
      try {
        this.isLoading = true;
        this.form.approved_by_id = this.user.id;

        let scheduler = await axios.patch(
          `/schedulers/${this.form.id}`,
          this.form
        );
        this.scheduler = scheduler.data;
        this.isLoading = false;
        this.$router.push("/schedulers");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>
