import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

// Layout
import UnAuthLayout from "../views/layouts/unauth-layout.vue";
import NoLayout from "../views/layouts/no-layout.vue";
import RoleLayout from "../views/layouts/role-layout.vue";
// Static
import HomePage from "../views/home";

// Auth
import LoginPage from "../views/auth/login.vue";
import RoleLoginPage from "../views/auth/role-login.vue";
import ForgotPasswordPage from "../views/auth/forgot-password.vue";
import ResetPasswordPage from "../views/auth/reset-password.vue";

// Permissions
import Permissions from "../views/permissions";
// ------------------------------------------------------------------------------------------
// Super Admin
// Users
import UsersPage from "../views/users";
import UserCreatePage from "../views/users/create.vue";
import UserUploadPage from "../views/users/upload.vue";
import UserIdPage from "../views/users/id.vue";

// User Documents
import UserDocumentsPage from "../views/users/documents";
import UserDocumentCreatePage from "../views/users/documents/create.vue";
import UserDocumentIdPage from "../views/users/documents/id.vue";

// Values
// import ValuesUpload from "@/views/values/upload.vue";
import Values from "@/views/values/index.vue";
import ValueCreate from "@/views/values/create.vue";
import ValueId from "@/views/values/id.vue";

// User Permission
import UserPermissions from "@/views/user-permissions/index.vue";
// ValueLists
// import ValueListUpload from "@/views/value-lists/upload.vue";
import ValueLists from "@/views/value-lists/index.vue";
import ValueListCreate from "@/views/value-lists/create.vue";
import ValueListId from "@/views/value-lists/id.vue";

// Safety And Emergency
import SafetyEmergency from "../views/safety-emergency";

// Company Vision
import CompanyVision from "../views/company-vision";

// Company Coe of conduct
import CompanyCodeConduct from "../views/company-code-of-conduct";

// Request forms
import RequestForm from "../views/request-forms";

// Profile
import Profile from "../views/profile";
import ProfileId from "../views/profile/id.vue";
import ProfileView from "../views/profile/view.vue";

// Attendances
import Attendances from "../views/attendances";
import AttendanceCreate from "../views/attendances/create.vue";

// Daily Works
import DailyWorks from "../views/daily-works";
import DailyWorkCopy from "../views/daily-works/copy.vue";
import DayWorkCreate from "@/views/daily-works/create.vue";

// // Training Videos
import TrainingVideos from "../views/training-videos";
import TrainingVideoLists from "../views/training-videos/list.vue";
import TrainingVideoCreate from "../views/training-videos/create.vue";
import TrainingVideoId from "../views/training-videos/id.vue";

// Training Video Details
import TrainingVideoDetails from "@/views/training-videos/details/index.vue";
import TrainingVideoDetailCreate from "@/views/training-videos/details/create.vue";
import TrainingVideoDetailId from "@/views/training-videos/details/id.vue";

// Training Videos
import SalarySlips from "../views/salary-slips";

// Setting
import Settings from "../views/settings";

// Weekl News
import WeeklyNews from "../views/weekly-news";
import WeeklyNewDetails from "../views/weekly-news/detail.vue";

// Material Request
import MaterailRequests from "../views/material-requests";
import ClientMaterailRequests from "../views/material-requests/client-index.vue";
import MaterailRequestCreate from "../views/material-requests/create.vue";
// import MaterailRequestId from "../views/material-requests/id.vue";

// Referral Form
import ReferralForms from "../views/referral-forms";

// Client Form
import ClientForms from "../views/client-forms";

// New Joinees
import NewJoinees from "../views/new-joinees";

// Letters
import Letters from "../views/letters";

// Billing History
import BillingHistories from "../views/billing-histories";

// Leave Application Form
import LeaveApplicationForms from "../views/leave-applications";
import LeaveApplicationFormCreate from "../views/leave-applications/create.vue";
import LeaveApplicationFormId from "../views/leave-applications/id.vue";

// Salary Advance Form
import SalaryAdvances from "../views/salary-advances";
import SalaryAdvanceCreate from "../views/salary-advances/create.vue";
import SalaryAdvanceId from "../views/salary-advances/id.vue";

// Requisition Form
import Requisitions from "../views/requisitions";
import RequisitionCreate from "../views/requisitions/create.vue";
import RequisitionId from "../views/requisitions/id.vue";

// Visiting Reports
import VistingReports from "../views/visiting-reports";
import VistingReportCreate from "../views/visiting-reports/create.vue";
import VistingReportId from "../views/visiting-reports/id.vue";

// News Articles
import NewsArticles from "../views/news-articles";
import NewsArticleCreate from "../views/news-articles/create.vue";
import NewsArticleId from "../views/news-articles/id.vue";

// Safety Videos
import SafetyVideos from "../views/safety-videos";
import SafetyVideoCreate from "../views/safety-videos/create.vue";
import SafetyVideoId from "../views/safety-videos/id.vue";

// Schgedulers
import Schedulers from "../views/schedulers";
import SchedulerCreate from "../views/schedulers/create.vue";
import AssignScheduler from "../views/schedulers/assign.vue";
import SchedulerDetails from "../views/schedulers/details.vue";
import SchedulerId from "../views/schedulers/id.vue";

// Client Visit Report
import ClientVisitForms from "../views/client-visit-forms";

// Other Request
import OtherRequests from "../views/other-requests";
import OtherRequestCreate from "../views/other-requests/create.vue";
import UnAuthOtherRequestCreate from "../views/other-requests/create.vue";
import OtherRequestId from "../views/other-requests/id.vue";

// Agreement Complainces
import AgreementComplainces from "../views/agreement-complainces";
import AgreementComplainceCreate from "../views/agreement-complainces/create.vue";
import AgreementComplainceId from "../views/agreement-complainces/id.vue";

// Billings
import Billings from "../views/billings";
import BillingCreate from "../views/billings/create.vue";
import BillingId from "../views/billings/id.vue";

// QrCodes
import QrCodes from "../views/qr-codes";
import QrCodeCreate from "../views/qr-codes/create.vue";
import QrCodeId from "../views/qr-codes/id.vue";

const routes = [
  // UnAuthLayout
  {
    path: "",
    name: "LoginPage",
    component: LoginPage,
    // path: "",
    // name: "UnAuthLayout",
    // component: UnAuthLayout,
    children: [],
  },
  //End UnAuthLayout

  // NoLayout
  {
    path: "/auth/login",
    name: "NoLayout",
    component: NoLayout,
    children: [
      {
        path: "/auth/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/superadmin/login",
        name: "SuperAdminLoginPage",
        component: RoleLoginPage,
      },
      {
        path: "/admin/login",
        name: "AdminLoginPage",
        component: RoleLoginPage,
      },
      {
        path: "/forgot-password",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
      },
      {
        path: "/reset-password",
        name: "ResetPasswordPage",
        component: ResetPasswordPage,
        props: true,
      },
      {
        path: "/unauth-other-requests/create",
        name: "UnAuthOtherRequestCreate",
        component: UnAuthOtherRequestCreate,
      },
    ],
  },
  // End NoLayout
  // RoleLayout
  {
    path: "",
    name: "RoleLayout",
    component: RoleLayout,
    children: [
      {
        path: "/home",
        name: "HomePage",
        component: HomePage,
      },
      {
        path: "/safety-emergency",
        name: "SafetyEmergency",
        component: SafetyEmergency,
      },
      {
        path: "/company-vision",
        name: "CompanyVision",
        component: CompanyVision,
      },
      {
        path: "/company-code-of-conduct",
        name: "CompanyCodeConduct",
        component: CompanyCodeConduct,
      },
      {
        path: "/request-forms",
        name: "RequestForm",
        component: RequestForm,
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/profile-view",
        name: "ProfileView",
        component: ProfileView,
      },
      {
        path: "/profile-update",
        name: "ProfileId",
        component: ProfileId,
      },
      {
        path: "/attendances",
        name: "Attendances",
        component: Attendances,
      },
      {
        path: "/attendance-create",
        name: "AttendanceCreate",
        component: AttendanceCreate,
      },
      {
        path: "/salary-slips",
        name: "SalarySlips",
        component: SalarySlips,
      },
      {
        path: "/daily-works",
        name: "DailyWorks",
        component: DailyWorks,
      },
      {
        path: "/daily-works/copy",
        name: "DailyWorkCopy",
        component: DailyWorkCopy,
      },
      {
        path: "/daily-works/:employeeId/create",
        name: "DayWorkCreate",
        component: DayWorkCreate,
      },
      {
        path: "/training-videos",
        name: "TrainingVideos",
        component: TrainingVideos,
      },
      {
        path: "/training-video-lists",
        name: "TrainingVideoLists",
        component: TrainingVideoLists,
      },
      {
        path: "/training-video-create",
        name: "TrainingVideoCreate",
        component: TrainingVideoCreate,
      },
      {
        path: "/training-video/:courseId",
        name: "TrainingVideoId",
        component: TrainingVideoId,
      },
      // F66 TrainingVideoDetails
      {
        path: "/training-video/:courseId/details",
        name: "TrainingVideoDetails",
        component: TrainingVideoDetails,
      },
      {
        path: "/training-video/:courseId/details/create",
        name: "TrainingVideoDetailCreate",
        component: TrainingVideoDetailCreate,
      },
      {
        path: "/training-video/:courseId/details/:detailId",
        name: "TrainingVideoDetailId",
        component: TrainingVideoDetailId,
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
      },
      {
        path: "/weekly-news",
        name: "WeeklyNews",
        component: WeeklyNews,
      },
      {
        path: "/weekly-news-details/:newsId",
        name: "WeeklyNewDetails",
        component: WeeklyNewDetails,
      },
      {
        path: "/material-requests",
        name: "MaterailRequests",
        component: MaterailRequests,
      },
      {
        path: "/material-request-create",
        name: "MaterailRequestCreate",
        component: MaterailRequestCreate,
      },
      {
        path: "/client-material-requests",
        name: "ClientMaterailRequests",
        component: ClientMaterailRequests,
      },
      {
        path: "/referral-forms",
        name: "ReferralForms",
        component: ReferralForms,
      },
      {
        path: "/client-forms",
        name: "ClientForms",
        component: ClientForms,
      },
      {
        path: "/new-joinees",
        name: "NewJoinees",
        component: NewJoinees,
      },
      {
        path: "/letters",
        name: "Letters",
        component: Letters,
      },
      {
        path: "/billing-histories",
        name: "BillingHistories",
        component: BillingHistories,
      },
      {
        path: "/leave-application-forms",
        name: "LeaveApplicationForms",
        component: LeaveApplicationForms,
      },
      {
        path: "/leave-application-create",
        name: "LeaveApplicationFormCreate",
        component: LeaveApplicationFormCreate,
      },
      {
        path: "/leave-applications/:leaveId",
        name: "LeaveApplicationFormId",
        component: LeaveApplicationFormId,
      },
      {
        path: "/salary-advance-forms",
        name: "SalaryAdvances",
        component: SalaryAdvances,
      },
      {
        path: "/salary-advance-create",
        name: "SalaryAdvanceCreate",
        component: SalaryAdvanceCreate,
      },
      {
        path: "/salary-advances/:salaryId",
        name: "SalaryAdvanceId",
        component: SalaryAdvanceId,
      },
      {
        path: "/requisitions",
        name: "Requisitions",
        component: Requisitions,
      },
      {
        path: "/requisition-create",
        name: "RequisitionCreate",
        component: RequisitionCreate,
      },
      {
        path: "/requisitions/:requisitionId",
        name: "RequisitionId",
        component: RequisitionId,
      },
      // Users
      {
        path: "/employees",
        name: "UsersPage",
        component: UsersPage,
      },
      {
        path: "/employees/create",
        name: "UserCreatePage",
        component: UserCreatePage,
      },
      {
        path: "/employees/upload",
        name: "UserUploadPage",
        component: UserUploadPage,
      },
      {
        path: "/employees/:userId",
        name: "UserIdPage",
        component: UserIdPage,
      },
      // User Documents
      {
        path: "/employees/:userId/documents",
        name: "UserDocumentsPage",
        component: UserDocumentsPage,
      },
      {
        path: "/employees/:userId/documents/create",
        name: "UserDocumentCreatePage",
        component: UserDocumentCreatePage,
      },
      {
        path: "/employees/:userId/documents/:docId",
        name: "UserDocumentIdPage",
        component: UserDocumentIdPage,
      },
      // Values
      {
        path: "/values",
        name: "Values",
        component: Values,
      },
      // {
      //   path: "/values/upload",
      //   name: "ValuesUpload",
      //   component: ValuesUpload,
      // },
      {
        path: "/values/create",
        name: "ValueCreate",
        component: ValueCreate,
      },
      {
        path: "/values/:valueId",
        name: "ValueId",
        component: ValueId,
      },
      // ValueLists
      {
        path: "/value-lists",
        name: "ValueLists",
        component: ValueLists,
      },
      // {
      //   path: "/value-lists/upload",
      //   name: "ValueListUpload",
      //   component: ValueListUpload,
      // },
      {
        path: "/value-lists/create",
        name: "ValueListCreate",
        component: ValueListCreate,
      },
      {
        path: "/value-lists/:valueListId",
        name: "ValueListId",
        component: ValueListId,
      },
      // UserPermissions
      {
        path: "/user-permissions",
        name: "UserPermissions",
        component: UserPermissions,
      },
      // Role wise Permissions
      {
        path: "/permissions",
        name: "Permissions",
        component: Permissions,
      },
      // Visting Reports
      {
        path: "/visiting-reports",
        name: "VistingReports",
        component: VistingReports,
      },
      {
        path: "/visiting-reports/create",
        name: "VistingReportCreate",
        component: VistingReportCreate,
      },
      {
        path: "/visiting-reports/:visitingId",
        name: "VistingReportId",
        component: VistingReportId,
      },
      // News Articles
      {
        path: "/news-articles",
        name: "NewsArticles",
        component: NewsArticles,
      },
      {
        path: "/news-articles/create",
        name: "NewsArticleCreate",
        component: NewsArticleCreate,
      },
      {
        path: "/news-articles/:newsId",
        name: "NewsArticleId",
        component: NewsArticleId,
      },
      // Safety Videos
      {
        path: "/safety-videos",
        name: "SafetyVideos",
        component: SafetyVideos,
      },
      {
        path: "/safety-videos/create",
        name: "SafetyVideoCreate",
        component: SafetyVideoCreate,
      },
      {
        path: "/safety-videos/:safetyId",
        name: "SafetyVideoId",
        component: SafetyVideoId,
      },
      // Schedulers
      {
        path: "/schedulers",
        name: "Schedulers",
        component: Schedulers,
      },
      {
        path: "/schedulers/create",
        name: "SchedulerCreate",
        component: SchedulerCreate,
      },
      {
        path: "/schedulers/assign",
        name: "AssignScheduler",
        component: AssignScheduler,
      },
      {
        path: "/schedulers/:schedulerId/details",
        name: "SchedulerDetails",
        component: SchedulerDetails,
      },
      {
        path: "/schedulers/:schedulerId",
        name: "SchedulerId",
        component: SchedulerId,
      },
      // Client Visit Form
      {
        path: "/client-visit-forms",
        name: "ClientVisitForms",
        component: ClientVisitForms,
      },
      // Other Request
      {
        path: "/other-requests",
        name: "OtherRequests",
        component: OtherRequests,
      },
      {
        path: "/other-requests/create",
        name: "OtherRequestCreate",
        component: OtherRequestCreate,
      },
      {
        path: "/other-requests/:otherReqId",
        name: "OtherRequestId",
        component: OtherRequestId,
      },
      // Agreement And Compplainces
      {
        path: "/agreement-complaiances",
        name: "AgreementComplainces",
        component: AgreementComplainces,
      },
      {
        path: "/agreement-complaiances/create",
        name: "AgreementComplainceCreate",
        component: AgreementComplainceCreate,
      },
      {
        path: "/agreement-complaiances/:agreeId",
        name: "AgreementComplainceId",
        component: AgreementComplainceId,
      },
      // Billings
      {
        path: "/billings",
        name: "Billings",
        component: Billings,
      },
      {
        path: "/billings/create",
        name: "BillingCreate",
        component: BillingCreate,
      },
      {
        path: "/billings/:billId",
        name: "BillingId",
        component: BillingId,
      },
      // QrCodes
      {
        path: "/qr-codes",
        name: "QrCodes",
        component: QrCodes,
      },
      {
        path: "/qr-codes/create",
        name: "QrCodeCreate",
        component: QrCodeCreate,
      },
      {
        path: "/qr-codes/:qrId",
        name: "QrCodeId",
        component: QrCodeId,
      },
    ],
  },
  // End RoleLayout
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/" || to.path === "/auth/login") {
    if (store.getters["auth/authenticated"]) {
      return next({
        path: "/home",
      });
    }
    next();
  } else if (
    to.path == "/forgot-password" ||
    to.path == "/reset-password" ||
    to.path == "/unauth-other-requests/create"
  ) {
    return next();
  } else if (!store.getters["auth/authenticated"]) {
    return next({
      path: "/auth/login",
    });
  }
  next();
});

router.afterEach((to) => {
  // // Remove initial loading
  // const appLoading = document.getElementById("loading-bg");
  // if (appLoading) {
  //   appLoading.style.display = "none";
  // }
});

export default router;
