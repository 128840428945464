<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <v-btn
            fab
            x-small
            class="mx-2 mb-2"
            color="white"
            @click="$router.back()"
          >
            <v-icon light> mdi-arrow-left </v-icon>
          </v-btn>
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="t-xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="pt-4">
                <v-col>
                  <v-toolbar-title> Employee Name: </v-toolbar-title>
                </v-col>
                <v-col>
                  <v-toolbar-title class="blue--text">
                    {{ this.user.full_name }}
                  </v-toolbar-title>
                </v-col>
              </v-row>

              <p class="py-3 text-subtitle-1">Select your request type:</p>
              <!-- Category Cards -->
              <template v-if="user.roles[0].name != 'Client'">
                <v-card
                  rounded="xl"
                  class="custom-border px-4 my-6"
                  elevation="10"
                  v-for="formtype in RequestFormTypes"
                  :key="formtype.id"
                >
                  <router-link
                    :to="formtype.router"
                    style="text-decoration: none"
                  >
                    <v-row align="center">
                      <v-col cols="2">
                        <v-img
                          :src="formtype.image"
                          alt="Salary Advance"
                          width="33"
                          height="34"
                        ></v-img>
                      </v-col>

                      <v-col>
                        <span class="text-subtitle-1 font-weight-medium">
                          {{ formtype.title }}
                        </span>
                      </v-col>
                    </v-row>
                  </router-link>
                </v-card>
              </template>
              <template v-if="user.roles[0].name == 'Client'">
                <v-card
                  rounded="xl"
                  class="custom-border px-4 my-6"
                  elevation="10"
                  v-for="formtype in ClientRequestFormTypes"
                  :key="formtype.id"
                >
                  <router-link
                    :to="formtype.router"
                    style="text-decoration: none"
                  >
                    <v-row align="center">
                      <v-col cols="2">
                        <v-img
                          :src="formtype.image"
                          alt="Salary Advance"
                          width="33"
                          height="34"
                        ></v-img>
                      </v-col>

                      <v-col>
                        <span class="text-subtitle-1 font-weight-medium">
                          {{ formtype.title }}
                        </span>
                      </v-col>
                    </v-row>
                  </router-link>
                </v-card>
              </template>

              <v-row justify="center">
                <v-col align="center">
                  <v-img
                    :src="require('../../assets/voe_imgs/form.png')"
                    alt="Logo"
                    width="180"
                    height="180"
                  ></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container> -->
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      RequestFormTypes: [
        {
          title: "Leave Application",
          image: require("@/assets/voe_imgs/leave.png"),
          router: "/leave-application-forms",
        },
        {
          title: "Salary Advance",
          image: require("@/assets/voe_imgs/salary.png"),
          router: "/salary-advance-forms",
        },
        {
          title: "Uniform request",
          image: require("@/assets/voe_imgs/uniform.png"),
          router: "/requisitions?type=Uniform",
        },
        {
          title: "ID card request",
          image: require("@/assets/voe_imgs/id-card.png"),
          router: "/requisitions?type=Id Card",
        },
        {
          title: "Shoe request",
          image: require("@/assets/voe_imgs/shoe.png"),
          router: "/requisitions?type=Shoe",
        },
        {
          title: "Other request",
          image: require("@/assets/voe_imgs/slip.png"),
          router: "/other-requests",
        },
      ],
      ClientRequestFormTypes: [
        {
          title: "Other request",
          image: require("@/assets/voe_imgs/slip.png"),
          router: "/other-requests",
        },
      ],
    };
  },
};
</script>
<style scoped>
.custom-border {
  border-color: #ff0000; /* Replace with your desired border color */
}
</style>
