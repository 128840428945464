<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card elevation="10" align="center" class="mt-4" rounded readonly="xl">
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0 mt-2">
            <h5 class="text-h6" align="left">Date of Application</h5>
            <v-col class="pa-0 pt-4">
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                readonly
                v-model="form.date_of_application"
                placeholder="Date of Application"
                outlined
              ></v-text-field>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name of Employee</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.employee_name"
              placeholder="Employee Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Designation</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.designation"
              placeholder="Designation"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Client Name</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.client_name"
              placeholder="Client Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Location</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.location"
              placeholder="Location"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row class="mx-4 mt-2 my-2 pb-4"> -->
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Advance Amount</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.advanced_amount"
              placeholder="Advance Amount"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Reason for Amount</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.reason_for_amount"
              placeholder="Reason for Amount"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">No of Installment:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.no_of_installment"
              placeholder="No of Installment"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Witness 1:</h5>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.witness_1_name"
              placeholder="Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Phone no:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.witness_1_phone"
              placeholder="Phone No"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Witness 2:</h5>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.witness_2_name"
              placeholder="Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Phone no:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.witness_2_phone"
              placeholder="Phone No"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Signature of Employee:</h5>
            <input
              class="mt-4"
              type="file"
              name="signature_path"
              ref="signature_path"
              id=""
            />
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Is Approved:</h5>
            <v-switch
              color="orange"
              v-model="form.is_approved"
              align="center"
              :readonly="roleName == 'Employee'"
            ></v-switch>
          </v-col>
        </v-row>
        <hr class="mx-5" />
        <v-row class="mx-4 mt-2 my-2" v-if="form.approved_by != ''">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Approved By:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="form.approved_by.full_name"
              placeholder="Approved By"
              outlined
            ></v-text-field>
            <!-- v-model="form.supervisor.full_name" -->
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2" v-if="form.approved_date != ''">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Approved Date:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              readonly
              v-model="form.approved_date"
              placeholder="Approved Date"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2" v-if="form.approved_amount != ''">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Approved Amount:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              :readonly="roleName == 'Employee'"
              v-model="form.approved_amount"
              placeholder="Approved Amount"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          class="mx-4 mt-2 my-2 pb-6"
          v-if="form.approved_installments != ''"
        >
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Approved no of Installment:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              :readonly="roleName == 'Employee'"
              v-model="form.approved_installments"
              placeholder="Approved no of Installment"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let form = await axios.get(
        `/salary_advance_requests/${this.$route.params.salaryId}`
      );
      this.form = form.data.data;
      this.isLoading = false;
    },
    async save() {
      try {
        this.isLoading = true;
        this.form.approved_by_id = this.user.id;
        this.form.approved_date = moment().format("YYYY-MM-DD");
        let salary = await axios.patch(
          `/salary_advance_requests/${this.form.id}`,
          this.form
        );
        this.salary = salary.data;
        this.isLoading = false;
        this.$router.push("/salary-advance-forms");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
  },
};
</script>
