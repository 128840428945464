<template>
  <v-main>
    <v-container fluid
      ><v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>

      <v-card elevation="10" align="center" class="mt-2" rounded="xl">
        <v-row class="mx-4 mt-5 my-2">
          <v-col class="pa-0 mt-4">
            <h5 class="text-h6" align="left">Company Name</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.client_name"
              placeholder="Company Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Location</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.location"
              placeholder="Location"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-3 my-2">
          <v-col class="pa-0">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <th>Sr No</th>
                  <th class="sticky-header">Housekeeping Services</th>
                  <th colspan="4">Score (choose one for each service)</th>
                  <th style="min-width: 220px">(Please specify)</th>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td class="sticky-column"></td>
                    <td>
                      <v-icon>mdi-emoticon-angry-outline</v-icon>
                    </td>
                    <td>
                      <v-icon>mdi-emoticon-sad-outline</v-icon>
                    </td>
                    <td>
                      <v-icon>mdi-emoticon-outline</v-icon>
                    </td>
                    <td>
                      <v-icon>mdi-emoticon-cool-outline</v-icon>
                    </td>
                    <td></td>
                  </tr>
                  <tr
                    v-for="(feedback_service, at) in form.feedback_services"
                    :key="`feedback_service${at}`"
                  >
                    <td>{{ 1 + at }}</td>
                    <td class="sticky-column">
                      <input
                        v-uppercase
                        type="text"
                        class="form-control"
                        placeholder="Feedback Services"
                        v-model="feedback_service.service_name"
                        readonly
                      />
                    </td>
                    <td>
                      <v-checkbox
                        v-model="feedback_service.score_1"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="feedback_service.score_2"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="feedback_service.score_3"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="feedback_service.score_4"
                      ></v-checkbox>
                    </td>
                    <td style="min-width: 220px">
                      <v-text-field
                        hide-details=""
                        solo
                        dense
                        rounded
                        v-model="feedback_service.specify"
                        outlined
                        class="blue--text"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-3 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">
              Please specify below the overall Housekeeping Service experience
              with V Alliance
            </h5>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2">
          <v-col class="pa-0">
            <v-radio-group v-model="form.overall" row>
              <v-radio
                on-icon="mdi-emoticon-angry-outline"
                label="Poor"
                value="Poor"
              ></v-radio>
              <v-radio
                on-icon="mdi-emoticon-sad-outline"
                label="Needs Improvement"
                value="Needs Improvement"
              ></v-radio>

              <v-radio
                on-icon="mdi-emoticon-outline"
                label="Good"
                value="Good"
              ></v-radio>

              <v-radio
                on-icon="mdi-emoticon-cool-outline"
                label="Excellent"
                value="Excellent"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">
              Please add your comments / suggestions if any:
            </h5>
            <v-textarea
              outlined
              name="input-7-4"
              label="Description"
              v-model="form.description"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.name"
              placeholder="Name"
              outlined
              class="blue--text"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Mobile No</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.mobile_no"
              placeholder="Mobile No"
              outlined
              class="blue--text"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Designation</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.designation"
              placeholder="Designation"
              outlined
              class="blue--text"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Signature</h5>
            <input type="file" ref="signature" style="min-width: 140px" />
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col align="center">
            <v-btn
              @click="save"
              min-width="200"
              :loading="isLoading"
              color="blue"
              rounded
              max-width="100"
              dark
              >Save Details</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "VistingReport",
  data() {
    return {
      form: {
        feedback_services: [],
      },
      feedbacks: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getMasters();
    this.form.site_id = this.site.id;
    this.form.user_id = this.user.id;
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("feedbacks/masters");
      masters = masters.data;
      masters.feedback_services.forEach((feedback_service) => {
        this.form.feedback_services.push({
          id: feedback_service.id,
          service_name: feedback_service.description,
          score_1: false,
          score_2: false,
          score_3: false,
          score_4: false,
          specify: "",
        });
      });
    },
    async save() {
      try {
        this.isLoading = true;
        let feedback = await axios.post("/feedbacks", this.form);
        this.feedback = feedback.data.data;
        this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/visiting-reports");
      } catch (e) {
        this.isLoading = false;
      }
    },

    async handleFileUpload() {
      this.isLoading = true;
      if (this.form.client_name == undefined) {
        this.form.client = "";
      }
      this.signature_path = this.$refs.signature.files[0];
      let formData = new FormData();
      formData.append("id", this.feedback.id);
      formData.append("signature_path", this.signature_path);
      formData.append("client_name", this.form.client_name);
      await axios
        .post("upload_feedback_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.data) this.feedback = response.data.data;
          this.isLoading = false;
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.sticky-column {
  position: sticky;
  left: 0;
  background-color: white; /* Match background color of table header */
  z-index: 1; /* Ensure the fixed column appears above other columns */
}
.sticky-header {
  position: sticky;
  left: 0;
  background-color: white; /* Match background color of table header */
  z-index: 3 !important; /* Ensure the fixed column appears above other columns */
}
</style>
