<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
            min-height="430"
          >
            <v-row class="ma-0">
              <v-col class="pa-0">
                <h2 class="mx-4 my-4">Training Videos</h2>
              </v-col>
            </v-row>
            <template v-if="courses.length > 0">
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-card
                    rounded="xl"
                    class="custom-border px-4 mt-2 mb-4 mx-3"
                    elevation="10"
                    v-for="course in courses"
                    :key="course.id"
                  >
                    <!-- <router-link :to="course.router" style="text-decoration: none"> -->
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0 ma-2" style="width: 308px">
                        <span class="text-subtitle-1 font-weight-medium">
                          {{ course.course_name }}
                        </span>
                      </v-col>
                      <v-col class="pa-0 ma-2" align="right">
                        <v-btn
                          class="mx-2"
                          color="blue lighten-5"
                          :to="`/training-video/${course.id}`"
                          dark
                          fab
                          right
                          width="40"
                          height="40"
                        >
                          <v-icon color="blue" size="20">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          class="ml-2"
                          color="blue lighten-5"
                          :to="`/training-video/${course.id}/details`"
                          dark
                          fab
                          right
                          width="40"
                          height="40"
                        >
                          <v-icon color="blue" size="20">mdi-eye</v-icon>
                        </v-btn>
                      </v-col>
                      <!-- <v-col class="pa-0 ma-2" align="right">
                      
                    </v-col> -->
                    </v-row>
                    <!-- </router-link> -->
                  </v-card>
                </v-col>
              </v-row>
            </template>
            <template v-if="courses.length == 0">
              <v-row justify="center">
                <v-col align="center" style="min-height: 100px">
                  <v-progress-circular
                    v-if="isLoading == true"
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                  <span v-else> No training video found. </span>
                </v-col>
              </v-row>
            </template>
          </v-card>
          <v-fab-transition>
            <v-btn
              color="blue lighten-5"
              to="/training-video-create"
              dark
              absolute
              bottom
              right
              fab
            >
              <v-icon color="blue">mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      courses: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let courses = await axios.get(`/courses`);
      this.courses = courses.data.data;
      this.isLoading = false;
    },
  },
};
</script>
