<template>
  <v-main>
    <v-container fluid>
      <v-card elevation="10" align="center" class="mt-12" rounded="xl">
        <v-row class="mx-4 mt-2 my-2">
          <v-col align="center">
            <h5 class="text-h6" align="left">Select from employee</h5>
            <v-select
              solo
              dense
              rounded
              hide-details=""
              v-model="selectedEmployee"
              :items="employees"
              label="Employee"
            ></v-select>
            <v-row class="ma-0 mt-3">
              <v-col align="center" class="pa-0 mr-1">
                <v-select
                  solo
                  hide-details=""
                  dense
                  rounded
                  v-model="form.selectedMonth"
                  :items="months"
                  label="Month"
                ></v-select>
              </v-col>
              <v-col align="center" class="pa-0 ml-1">
                <v-select
                  solo
                  hide-details=""
                  dense
                  rounded
                  v-model="form.selectedYear"
                  :items="years"
                  label="Year"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-2 my-2">
          <v-col class="ma-0">
            <h5 class="text-h6" align="left">Select to employees</h5>
            <v-autocomplete
              v-model="form.to_employee_ids"
              :items="employees"
              outlined
              rounded
              dense
              solo
              hide-details=""
              chips
              small-chips
              label="Outlined"
              multiple
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col align="center">
            <v-btn
              @click="copy"
              min-width="200"
              :loading="isLoading"
              color="blue"
              rounded
              max-width="100"
              dark
              >Copy</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card rounded="xl">
        <v-card-title class="text-h5" align="center">
          {{ msg }}
        </v-card-title>

        <v-card-actions justify="center">
          <v-row justify="center" class="my-4">
            <v-btn
              color="blue darken-1"
              rounded
              @click="dialog = false"
              class="mx-2"
              dark
            >
              Ok
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "SafetyVideos",
  data() {
    return {
      form: {},
      dialog: false,
      isLoading: false,
      employees: [],
      selectedEmployee: "",
      months: [
        { text: "January", value: "1" },
        { text: "February", value: "2" },
        { text: "March", value: "3" },
        { text: "April", value: "4" },
        { text: "May", value: "5" },
        { text: "June", value: "6" },
        { text: "July", value: "7" },
        { text: "August", value: "8" },
        { text: "September", value: "9" },
        { text: "October", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" },
      ],
      years: [
        { text: "2022", value: "2022" },
        { text: "2023", value: "2023" },
        { text: "2024", value: "2024" },
      ],
      msg: "",
    };
  },
  mounted() {
    this.getMasters();
    this.form.site_id = this.site.id;
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`/users?role_id=3`);
      masters = masters.data.data;
      masters.forEach((user) => {
        this.employees.push({
          id: user.id,
          text: user.full_name,
          value: user.id,
        });
      });
    },
    async copy() {
      console.log(this.form.to_employee);
      try {
        this.isLoading = true;
        let query =
          "from_employee_id=" +
          this.selectedEmployee +
          "&from_month=" +
          this.form.selectedMonth +
          "&from_year=" +
          this.form.selectedYear +
          "&to_employee_ids=" +
          this.form.to_employee_ids;
        console.log(query);
        let copyDailyTasks = await axios.get(`/copy_daily_tasks?${query}`);
        this.msg = copyDailyTasks.data.message;
        this.dialog = true;

        this.isLoading = false;

        //   this.$router.push("/safety-videos");
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
