<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="t-xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row justify="center" class="ma-0">
                <v-col align="center" class="pa-0">
                  <v-img
                    :src="require('../../assets/voe_imgs/settingDemo.png')"
                    alt="Logo"
                    width="200"
                    height="250"
                  ></v-img>
                </v-col>
              </v-row>

              <!-- Category Cards -->
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-card
                    rounded="xl"
                    class="custom-border px-4 my-6"
                    elevation="10"
                    v-for="settingType in SettingTypes"
                    :key="settingType.id"
                  >
                    <v-row align="center">
                      <v-col cols="2">
                        <v-img
                          :src="settingType.image"
                          alt="Salary Advance"
                          width="33"
                          height="34"
                        ></v-img>
                      </v-col>

                      <v-col>
                        <span class="text-subtitle-1 font-weight-medium">
                          {{ settingType.title }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <!-- Reset Password -->
              <v-row class="ma-0">
                <v-col class="pa-0">
                  <v-card
                    to="reset-password"
                    rounded="xl"
                    class="custom-border px-4 my-6"
                    elevation="10"
                  >
                    <v-row align="center">
                      <v-col cols="2">
                        <v-img
                          :src="
                            require('../../assets/voe_imgs/change-password-5.png')
                          "
                          alt="Salary Advance"
                          width="33"
                          height="34"
                        ></v-img>
                      </v-col>

                      <v-col>
                        <span class="text-subtitle-1 font-weight-medium">
                          Change Password
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <v-row justify="center" class="ma-0 mt-12 mx-auto px-auto">
                <v-col align="right" class="pa-0 pr-5">
                  <v-img
                    :src="require('../../assets/voe_imgs/logout.png')"
                    alt="Logo"
                    width="50"
                    height="50"
                    @click="logout"
                  ></v-img>
                </v-col>
                <v-col align="left" class="pa-0 pr-6">
                  <span class="text-h4" @click="logout"> Log Out</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      SettingTypes: [
        {
          title: "Change Language",
          image: require("@/assets/voe_imgs/language.png"),
        },
        {
          title: "Terms of Use",
          image: require("@/assets/voe_imgs/terms-of-use.png"),
        },
        {
          title: "Privacy Policy",
          image: require("@/assets/voe_imgs/policy.png"),
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut().then(() => {
        window.history.pushState({}, "", "/"); // Clear historys

        location.replace("/login");
      });
    },
  },
};
</script>
<style scoped>
.custom-border {
  border-color: #ff0000; /* Replace with your desired border color */
}
</style>
