import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import DatetimePicker from "vuetify-datetime-picker";
Vue.use(DatetimePicker);

import router from "./router";
import store from "./store";

import VueGoogleCharts from "vue-google-charts/legacy";
Vue.use(VueGoogleCharts);

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

import VueQRCodeComponent from "vue-qrcode-component";

// Register the Vue component
Vue.component("qr-code", VueQRCodeComponent);

Vue.config.productionTip = false;

require("@/store/subscriber");

require("./mixin.js");
require("./axios.js");
// require('./directives.js')
// require('./components.js')
require("./filters.js");

import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
