<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="ma-0">
          <v-col align="center">
            <span class="blue--text">Supervisor Name:</span>
          </v-col>
          <v-col align="center">
            <span>{{ user.full_name }}</span>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col align="center">
            <img
              :src="require('@/assets/voe_imgs/referral-form-deom.png')"
              alt="Profile"
              height="140"
              width="160"
            />
          </v-col>
        </v-row>

        <v-row class="mx-4 my-2 mt-2">
          <v-col class="pa-0 mx-1" style="min-width: 165px">
            <h5 class="text-h6" align="left">Name</h5>
          </v-col>
          <v-col class="pa-0 mx-1" style="min-width: 165px">
            <v-text-field
              solo
              dense
              rounded
              v-model="form.name"
              placeholder="Name"
              outlined
              :error-messages="errors.name"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="pa-0 mx-1" style="min-width: 165px">
            <h5 class="text-h6" align="left">Phone No</h5>
          </v-col>
          <v-col class="pa-0 mx-1" style="min-width: 165px">
            <v-text-field
              solo
              dense
              rounded
              v-model="form.phone_no"
              placeholder="Phone No"
              outlined
              :error-messages="errors.phone_no"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="pa-0 mx-1" style="min-width: 165px">
            <h5 class="text-h6" align="left">Address</h5>
          </v-col>
          <v-col class="pa-0 mx-1" style="min-width: 165px">
            <v-text-field
              solo
              dense
              rounded
              v-model="form.address"
              placeholder="Address"
              outlined
              :error-messages="errors.address"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="pa-0 mx-1" style="min-width: 165px">
            <h5 class="text-h6" align="left">Designation</h5>
          </v-col>
          <v-col class="pa-0 mx-1" style="min-width: 165px">
            <v-text-field
              solo
              dense
              rounded
              v-model="form.designation"
              placeholder="Designation"
              outlined
              :error-messages="errors.designation"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mx-4 my-2">
          <v-col class="pa-0 mx-1 my-2" style="min-width: 165px">
            <h5 class="text-h6" align="left">Photo Upload</h5>
          </v-col>
          <v-col class="pa-0 mx-4 my-2" style="min-width: 165px">
            <input type="file" ref="image_path" />
          </v-col>
        </v-row>

        <!-- <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0 mx-4 my-2" style="min-width: 165px">
            <h5 class="text-h6" align="left"></h5>
          </v-col>
          <v-col class="pa-0 mx-4 my-2" style="min-width: 165px">
             <h5 class="text-h6" align="left">Photo Upload</h5>
            <input type="file" ref="image_path" />
          </v-col>
        </v-row> -->
        <v-row class="ma-0">
          <v-col class="pa-0 mx-4 my-2" style="min-width: 165px">
            <h5 class="text-h6" align="left">Pan Card details</h5>
          </v-col>
          <v-row class="ma-0">
            <v-col class="pa-0 mx-4 my-2" style="min-width: 165px">
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                v-model="form.pan_no"
                placeholder="Pan Card"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <input
                type="file"
                ref="pan_card_image"
                style="min-width: 140px"
              />
            </v-col>
          </v-row>
        </v-row>
        <v-row class="ma-0 my-2">
          <v-col class="pa-0 mx-4 my-2" style="min-width: 165px">
            <h5 class="text-h6" align="left">Adhaar Card details</h5>
          </v-col>
          <v-row class="ma-0">
            <v-col class="pa-0 mx-4 my-2" style="min-width: 165px">
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                v-model="form.aadhar_no"
                placeholder="Adhaar Card"
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <input
                type="file"
                ref="adhaar_card_image"
                style="min-width: 140px"
              />
            </v-col>
          </v-row>
        </v-row>
        <v-row class="ma-0 mx-4 my-2">
          <v-col class="pa-0 my-2">
            <h5 class="text-h6" align="left">Bank Details</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.bank_no"
              placeholder="Bank Account Number"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 mx-4 my-2">
          <v-col class="pa-0 my-2">
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.bank_ifsc_code"
              placeholder="Enter IFSC Code"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-0 mx-4 my-4">
          <v-col class="pa-0 mx-1 my-2" style="min-width: 165px">
            <h5 class="text-h6" align="left">Passbook Upload</h5>
          </v-col>
          <v-col class="pa-0 my-2 mb-3">
            <input type="file" ref="passbook_image" />
            <span v-if="error != ''" style="color: red; font-size: 12px">{{
              error
            }}</span>
          </v-col>
        </v-row>
        <!-- <v-row class="ma-0 mx-4 my-4">
          <v-col class="pa-0 my-2 mb-3">
            <input type="file" ref="passbook_image" />
          </v-col>
        </v-row> -->
        <v-row class="ma-0 mx-4 pb-6">
          <v-col align="center" class="pa-0">
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: {},
      response: [],
      error: "",
    };
  },

  methods: {
    async save() {
      this.isLoading = true;
      this.form.referrer_id = this.user.id;
      if (this.$refs.passbook_image.files[0] == null) {
        this.error = "The passbook image field is required.";
      }
      let response = await axios.post(`/referral_requests`, this.form);
      this.response = response.data.data;
      this.handleFileUpload();
      this.isLoading = false;
    },
    async handleFileUpload() {
      this.isLoading = true;
      this.image_path = this.$refs.image_path.files[0];
      this.adhaar_card_image = this.$refs.adhaar_card_image.files[0];
      this.pan_card_image = this.$refs.pan_card_image.files[0];
      this.passbook_image = this.$refs.passbook_image.files[0];

      let formData = new FormData();
      formData.append("id", this.response.id);
      formData.append("imagepath", this.image_path);
      formData.append("pan_image_path", this.adhaar_card_image);
      formData.append("aadhar_image_path", this.pan_card_image);
      formData.append("passbook_image_path", this.passbook_image);
      await axios
        .post("upload_referral_request_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.isLoading = false;
          // alert("Uploaded Successfully");
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      this.isLoading = false;
    },
  },
};
</script>
