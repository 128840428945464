<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <v-btn
            fab
            x-small
            class="mx-2 mb-2"
            color="white"
            @click="$router.back()"
          >
            <v-icon light> mdi-arrow-left </v-icon>
          </v-btn>
          <!-- Main Container -->
          <v-card
            v-if="roleName == 'Employee' || roleName == 'Supervisor'"
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0">
                <v-col class="pa-0" style="min-width: 250px"
                  ><p class="py-3 text-subtitle-1">
                    Apply New Leave Application:
                  </p></v-col
                >
                <v-col class="pa-0">
                  <router-link to="/leave-application-create">
                    <v-btn color="white" dark fab>
                      <v-icon color="blue lighten1">mdi-plus</v-icon>
                    </v-btn>
                  </router-link>
                </v-col>
              </v-row>

              <!-- Category Cards -->
              <template v-if="leave_applications.length > 0">
                <v-card
                  rounded="xl"
                  class="custom-border px-4 my-6"
                  elevation="10"
                  v-for="leave_application in leave_applications"
                  :key="leave_application.id"
                >
                  <!-- :to="leave" -->
                  <router-link
                    :to="`/leave-applications/${leave_application.id}`"
                    style="text-decoration: none"
                  >
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          From Date:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ leave_application.from_date }}
                        </span>
                      </v-col>

                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          To Date:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ leave_application.to_date }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Is Approved:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{
                            leave_application.is_approved == 1 ? "Yes" : "No"
                          }}
                        </span>
                      </v-col>

                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Approved Date:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ leave_application.approved_date }}
                        </span>
                      </v-col>
                    </v-row>
                  </router-link>
                </v-card>
              </template>
              <template v-else>
                <v-row justify="center" class="pb-6">
                  <v-col align="center">
                    <span> No Leave Applications Found.</span>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card>
          <v-card
            v-if="roleName == 'Admin' || roleName == 'Supervisor'"
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0">
                <v-col class="pa-0" style="min-width: 250px"
                  ><p class="py-3 text-subtitle-1">
                    Leaves Applied by
                    {{ roleName == "Admin" ? "Supervisors" : "Employees" }} :
                  </p></v-col
                >
              </v-row>

              <!-- Category Cards -->
              <template v-if="employee_leave_applications.length > 0">
                <v-card
                  rounded="xl"
                  class="custom-border px-4 my-6"
                  elevation="10"
                  v-for="leave_application in employee_leave_applications"
                  :key="leave_application.id"
                >
                  <!-- :to="leave" -->
                  <router-link
                    :to="`/leave-applications/${leave_application.id}`"
                    style="text-decoration: none"
                  >
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          {{ roleName == "Admin" ? "Supervisor" : "Employee" }}
                          Name:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ leave_application.user.full_name }}
                        </span>
                      </v-col>
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Employee Code:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ leave_application.user.employee_id }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          From Date:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ leave_application.from_date }}
                        </span>
                      </v-col>

                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          To Date:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ leave_application.to_date }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row align="center" class="ma-0">
                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Is Approved:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{
                            leave_application.is_approved == 1 ? "Yes" : "No"
                          }}
                        </span>
                      </v-col>

                      <v-col class="pa-0">
                        <span class="text-subtitle-1 font-weight-medium">
                          Approved Date:
                        </span>
                        <br />
                        <span
                          class="text-subtitle-1 font-weight-medium black--text"
                        >
                          {{ leave_application.approved_date }}
                        </span>
                      </v-col>
                    </v-row>
                  </router-link>
                </v-card>
              </template>
              <template v-else>
                <v-row justify="center" class="pb-6">
                  <v-col align="center">
                    <span> No Leave Applications Found.</span>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      leave_applications: [],
      employee_leave_applications: [],
    };
  },
  mounted() {
    if (this.roleName == "Admin") {
      this.getSupervisorData();
    } else {
      this.getData();
    }
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let leave_applications = await axios.get(`leave_application_requests`);
      this.leave_applications = leave_applications.data.data;
      this.isLoading = false;
      this.getEmployeeData();
    },
    async getEmployeeData() {
      this.isLoading = true;
      let employee_leave_applications = await axios.get(
        `leave_application_requests?fetch_employees_leave=true`
      );
      this.employee_leave_applications = employee_leave_applications.data.data;
      this.isLoading = false;
    },
    async getSupervisorData() {
      this.isLoading = true;
      let employee_leave_applications = await axios.get(
        `leave_application_requests?fetch_supervisors_leave=true`
      );
      this.employee_leave_applications = employee_leave_applications.data.data;
      this.isLoading = false;
    },
  },
};
</script>
