<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="ma-0 pt-2">
          <v-col class="pa-0 mt-3">
            <span class="pr-0" style="min-width: 156px">Employee Name:</span>
          </v-col>
          <v-col class="pa-0 mr-3 mt-1" style="max-width: 163px">
            <v-select
              solo
              dense
              rounded
              hide-details=""
              v-model="selectedEmployeeId"
              :items="employees"
              label="Employee"
              @change="getData()"
              clearable
              open-on-clear
            ></v-select>
          </v-col>
        </v-row>
        <hr class="mt-4 mx-4" />
        <v-row class="ma-0 pt-2">
          <v-col class="pa-0 ml-5" align="left">
            <span class="pr-0" style="min-width: 156px">Employee Profile:</span>
          </v-col>
        </v-row>

        <template v-if="selectedEmployeeId != ''">
          <v-row class="ma-0">
            <v-col align="left" style="max-width: 40px">
              <img
                :src="require('@/assets/voe_imgs/logo.png')"
                alt="Profile"
                height="50"
                width="50"
              />
            </v-col>
            <v-col>
              <v-row class="ma-0">
                <v-col class="blue--text text-h6 font-weighgt-bold pa-0">
                  {{ form.full_name }}
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0">
                  {{ calculateAge(form.dob) }} yrs,
                  {{ user.title == "Mr." ? "Male" : "Female" }}
                </v-col>
                <v-col class="pa-0"> {{ form.employee_id }} </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Designation</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                rounded
                readonly
                v-model="designation"
                placeholder="Designation"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Date of Birth</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                readonly
                rounded
                v-model="form.dob"
                placeholder="Date of Birth"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <v-row class="ma-0">
                <v-col class="pa-0"
                  ><h5 class="text-h6" align="left" style="min-width: 190px">
                    Adhaar Number
                  </h5></v-col
                >
                <v-col
                  class="text-decoration-underline pa-0 mt-2"
                  align="right"
                >
                  View Doc
                </v-col>
              </v-row>
              <v-text-field
                hide-details=""
                solo
                dense
                readonly
                rounded
                v-model="form.aadhar_no"
                placeholder="Adhaar Number"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <v-row class="ma-0">
                <v-col class="pa-0"
                  ><h5 class="text-h6" align="left" style="min-width: 190px">
                    Pan Card Number
                  </h5></v-col
                >
                <v-col
                  class="text-decoration-underline pa-0 mt-2"
                  align="right"
                >
                  View Doc
                </v-col>
              </v-row>
              <v-text-field
                hide-details=""
                solo
                dense
                readonly
                rounded
                v-model="form.pan_no"
                placeholder="Pan Card Number"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <h5 class="text-h6" align="left">Address Details</h5>
              <v-text-field
                hide-details=""
                solo
                dense
                readonly
                rounded
                placeholder="Address Details"
                outlined
              ></v-text-field>
              <!-- v-model="form.pan_no" -->
            </v-col>
          </v-row>
          <v-row class="mx-4 mt-2 my-2">
            <v-col class="pa-0">
              <v-row class="ma-0">
                <v-col class="pa-0"
                  ><h5 class="text-h6" align="left" style="min-width: 190px">
                    Previous Experience
                  </h5></v-col
                >
                <v-col
                  class="text-decoration-underline pa-0 mt-2"
                  align="right"
                >
                  View Doc
                </v-col>
              </v-row>
              <v-text-field
                hide-details=""
                solo
                dense
                readonly
                rounded
                placeholder="Previous Experience"
                outlined
              ></v-text-field>
              <!-- v-model="form.pan_no" -->
            </v-col>
          </v-row>
          <v-row class="ma-0 mr-4 pb-8">
            <v-col class="text-decoration-underline pa-0" align="right">
              Download resume
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row class="mt-12" justify="center">
            <v-col class="mx-9 mt-8" align="center">
              <span class="blue--text"
                >Please select an employee from the list to view his/her
                details</span
              >
            </v-col>
          </v-row>

          <v-row justify="center" class="mt-12">
            <v-col align="center" class="my-8">
              <v-img
                :src="require('../../assets/voe_imgs/new-joinee-demo.png')"
                alt="New Joinee"
                width="200"
                height="180"
              ></v-img>
            </v-col>
          </v-row>
        </template>
      </v-card>
    </v-container>
    <v-container class="footer-text" v-if="selectedEmployeeId != ''">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      form: {},
      employees: [],
      selectedEmployeeId: "",
      designation: "",
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get(`/users?role_id=3`);
      masters = masters.data.data;
      masters.forEach((user) => {
        this.employees.push({
          id: user.id,
          text: user.full_name,
          value: user.id,
        });
      });
    },
    async getData() {
      this.isLoading = true;
      let form = await axios.get(`/users/${this.selectedEmployeeId}`);
      this.form = form.data.data;

      const findDesignation = this.form.user_categories.find(
        (item) => item.category.category_name == "DESIGNATION"
      );

      this.designation = findDesignation
        ? findDesignation.category_list.name
        : "";
      // console.log(this.designation);
      this.isLoading = false;
    },
    calculateAge(birthdate) {
      const now = moment();
      const birthdateMoment = moment(birthdate);
      const years = now.diff(birthdateMoment, "years");
      return years;
    },
  },
};
</script>
