<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card elevation="10" align="center" class="mt-4" rounded="xl">
        <v-row class="mx-4 mt-2">
          <v-col class="pa-0 mt-2">
            <h5 class="text-h6" align="left">Date of Application</h5>
            <v-col class="pa-0 pt-4">
              <v-dialog
                ref="ApplicationDae"
                :close-on-content-click="false"
                v-model="date_of_application_menu"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.date_of_application"
                    label="Date of Application"
                    readonly
                    solo
                    dense
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.date_of_application"
                  scrollable
                  @input="date_of_application_menu = false"
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-col>
        </v-row>
        <v-row class="mx-4 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name of Employee</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.employee_name"
              placeholder="Employee Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Designation</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.designation"
              placeholder="Designation"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Client Name</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.client_name"
              placeholder="Client Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Location</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.location"
              placeholder="Location"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row class="mx-4 mt-2 my-2 pb-4"> -->
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name of Reporting Supervisor</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.name_of_reporting_supervisor"
              placeholder="Name of Reporting Supervisor"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Duration</h5>
            <v-row>
              <v-col class="pa-0 pt-4 mx-2">
                <v-dialog
                  ref="ApplicationDae"
                  :close-on-content-click="false"
                  v-model="from_date_menu"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.from_date"
                      label="From Date"
                      readonly
                      solo
                      dense
                      rounded
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.from_date"
                    scrollable
                    @input="from_date_menu = false"
                  >
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col class="pa-0 pt-4 mx-2">
                <v-dialog
                  ref="ApplicationDae"
                  :close-on-content-click="false"
                  v-model="to_date_menu"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.to_date"
                      label="To Date"
                      readonly
                      solo
                      dense
                      rounded
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.to_date"
                    scrollable
                    @input="(to_date_menu = false), calculateDays()"
                  >
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Total no of Days</h5>
            <!-- {{ days }} -->
            <v-text-field
              hide-details=""
              solo
              dense
              readonly
              rounded
              v-model="days"
              placeholder="Supervisor Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Reason for Leave</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.reason_for_leave"
              placeholder="Reason for Leave"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="center">
              Contact Details (In Case of an Emergency)
            </h5>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Name:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.name"
              placeholder="Name"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Address:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.address"
              placeholder="Address"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Phone no:</h5>
            <v-text-field
              hide-details=""
              solo
              dense
              rounded
              v-model="form.phone_no"
              placeholder="Phone No"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mx-4 mt-2 my-2">
          <v-col class="pa-0">
            <h5 class="text-h6" align="left">Signature of Employee:</h5>
            <input
              class="mt-4"
              type="file"
              name="signature_path"
              ref="signature_path"
              id=""
            />
          </v-col>
        </v-row>

        <v-row class="mx-4 mt-6 my-2 pb-6">
          <v-col align="center" class="pa-0">
            <!-- :loading="isLoading" -->
            <!-- @click="login" -->
            <v-btn
              min-width="250"
              color="blue"
              rounded
              max-width="100"
              dark
              @click="save"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      days: 0,
      date_of_application_menu: false,
      from_date_menu: false,
      to_date_menu: false,
      // date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      // .toISOString()
      // .substr(0, 10),
      form: {
        date_of_application: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        from_date: "",
        to_date: "",
        employee_name: "",
        designation: "",
        client_name: "",
        location: "",
        name_of_reporting_supervisor: "",
        reason_for_leave: "",
        name: "",
        address: "",
        phone_no: "",
      },
    };
  },

  methods: {
    calculateDays() {
      if (this.form.from_date && this.form.to_date) {
        const from = moment(this.form.from_date);
        const to = moment(this.form.to_date);
        this.days = to.diff(from, "days") + 1;
      } else {
        this.days = null;
      }
      this.form.total_no_of_days = this.days;
    },
    async save() {
      try {
        this.isLoading = true;
        console.log(this.form);
        this.form.user_id = this.user.id;
        let leave = await axios.post("/leave_application_requests", this.form);
        this.leave = leave.data;
        this.handleFileUpload();
        this.isLoading = false;
        this.$router.push("/request-forms");
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async handleFileUpload() {
      this.isLoading = true;
      let attachment = this.$refs.file?.files[0];
      const leaveId = this.leave.id;
      let formData = new FormData();
      formData.append("id", leaveId);
      formData.append("signature_path", attachment);
      await axios
        .post("upload_leave_application_request_image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .catch(function () {
          console.log("FAILURE!!");
        });
      if (this.leave) {
        this.success = true;
        this.form = [];
      }
      this.isLoading = false;
      // if (this.career_request) {
      //   this.success = true;
      //   this.form = [];
      // }
      // this.getData();
    },
  },
};
</script>
