<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
            min-height="430"
          >
            <v-row class="ma-0">
              <v-col class="pa-0">
                <h2 class="mx-4 my-4" style="width: 250px">Qr Code Lists</h2>
              </v-col>
              <v-col class="pa-0 ma-2">
                <v-fab-transition>
                  <v-btn
                    to="/qr-codes/create"
                    color="blue lighten-5"
                    dark
                    absolute
                    fab
                    width="50"
                    height="50"
                  >
                    <v-icon color="blue">mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
              </v-col>
            </v-row>
            <template v-if="complaint_qrs.length > 0">
              <v-row class="ma-0">
                <v-col class="pa-0 mx-3 mb-6">
                  <v-expansion-panels>
                    <v-expansion-panel
                      v-for="complaint_qr in complaint_qrs"
                      :key="complaint_qr.id"
                    >
                      <v-expansion-panel-header>
                        <v-row class="ma-0">
                          <v-col cols="10" class="pa-0"
                            ><span>{{ complaint_qr.name }}</span>
                          </v-col>
                          <v-col cols="2" class="pa-0" align="right"
                            ><v-fab-transition>
                              <v-btn
                                :to="`/qr-codes/${complaint_qr.id}`"
                                color="blue lighten-5"
                                dark
                                fab
                                x-small
                              >
                                <v-icon color="blue">mdi-pencil</v-icon>
                              </v-btn>
                            </v-fab-transition></v-col
                          >
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="ma-0 py-1">
                          <v-col class="pa-0" style="max-width: 100px">
                            <span>QR Code</span>
                          </v-col>
                          <v-col class="pa-0" align="left">
                            <qr-code
                              ref="qrCode"
                              :size="100"
                              :text="complaint_qr.link"
                              error-level="H"
                            ></qr-code>
                          </v-col>
                          <v-btn @click="downloadQRCode"
                            >Download QR Code</v-btn
                          >
                        </v-row>

                        <v-row class="ma-0 py-1">
                          <v-col class="pa-0" style="max-width: 100px">
                            <span>Date</span>
                          </v-col>
                          <v-col class="pa-0" align="left">
                            <span
                              >: {{ complaint_qr.created_at | ddmmyyyy }}</span
                            >
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </template>
            <template v-if="complaint_qrs.length == 0">
              <v-row justify="center">
                <v-col align="center" style="min-height: 100px">
                  <v-progress-circular
                    v-if="isLoading == true"
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                  <span v-else> No qr code found. </span>
                </v-col>
              </v-row>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import backbutton from "@/components/backbutton.vue";
export default {
  components: { backbutton },
  name: "QrCodes",
  data() {
    return {
      complaint_qrs: [],
      isLoading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      let complaint_qrs = await axios.get(`/complaint_qrs`);
      this.complaint_qrs = complaint_qrs.data.data;
      this.isLoading = false;
    },
    downloadQRCode() {
      // const qrElement = this.$refs.qrCode.$el.querySelector("canvas"); // Assumes the QR code is rendered in a canvas element
      const qrElement = this.$refs.qrCode[0].$el.querySelector("canvas");
      console.log(qrElement);
      // Assumes the QR code is rendered in a canvas element
      if (qrElement) {
        const image = qrElement.toDataURL("image/png"); // Get the data URL of the image
        const link = document.createElement("a");
        link.href = image;
        link.download = "qr-code.png";
        link.click();
      } else {
        console.error("QR code element not found or is not a canvas");
      }
    },
  },
};
</script>
