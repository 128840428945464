<template>
  <v-container fluid fill-height>
    <v-btn fab x-small class="mx-2 mb-2" color="white" @click="$router.back()">
      <v-icon light> mdi-arrow-left </v-icon>
    </v-btn>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8" lg="6">
        <v-row justify="center" class="ma-0">
          <v-col align="center" class="pa-0">
            <v-card elevation="0" rounded="xl" max-width="160" max-height="150">
              <v-card-text>
                <v-img
                  :src="require('../../assets/voe_imgs/logo.png')"
                  alt="Logo"
                  width="110"
                  height="110"
                ></v-img>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col align="center" class="pa-0">
            <h2>WE ALLIANCE SPARRKLE</h2>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col align="center">
            <v-img
              :src="require('../../assets/voe_imgs/login-image.png')"
              alt="Login Image"
              max-width="220"
            ></v-img>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col>
            <h2 style="color: black">Reset Your Password Here!</h2>
            <!-- <span class="text-subtitle"
              >Please enter your new password to continue</span
            > -->
          </v-col>
        </v-row>
        <v-form ref="loginForm" v-model="valid" class="mx-2">
          <v-row justify="center" class="ma-0">
            <v-col class="pa-0">
              <h5 class="text-h6">New Password</h5>
              <v-text-field
                solo
                dense
                rounded
                v-model="form.password"
                outlined
                placeholder="Password"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                :error-messages="errors.password"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-0">
            <v-col class="pa-0">
              <h5 class="text-h6">Confirm Password</h5>
              <v-text-field
                solo
                dense
                rounded
                v-model="form.password_confirmation"
                outlined
                placeholder="Confirm Password"
                prepend-inner-icon="mdi-lock"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                :error-messages="errors.password_confirmation"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center" class="ma-0">
            <v-col align="center" class="pa-0">
              <v-btn
                min-width="250"
                @click="resetPassword"
                :loading="isLoading"
                color="blue"
                rounded
                max-width="100"
                dark
                >Reset Password</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      valid: true,
      showPassword: false,
      showConfirmPassword: false,
      isLoading: false,
      form: {
        password: "",
        password_confirmation: "",
        email: "",
      },
    };
  },
  mounted() {
    this.form.email = this.$route.query.email;
  },
  methods: {
    async resetPassword() {
      this.isLoading = true;
      this.form.email = this.user.email;
      console.log(this.form);
      await axios
        .post(`/reset_password`, this.form)
        .then(() => {
          this.$router.push("/auth/login");
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
