<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <v-col
            class="pa-0 mt-0"
            align="center"
            v-if="user.roles[0].name == 'Employee'"
          >
            <h4 class="font-weight-bold mt-4 mb-0 text-uppercase white--text">
              Emp name: {{ user.full_name }}
            </h4>
          </v-col>
          <v-col align="center" v-else-if="user.roles[0].name == 'Supervisor'">
            <h5 class="text-h6" align="left">Select Client</h5>
            <v-select
              solo
              hide-details=""
              dense
              rounded
              v-model="form.site_id"
              :items="sites"
              label="Client"
              @change="getSiteWiseEmployees()"
            ></v-select>
            <v-select
              class="pa-0 mt-3"
              solo
              dense
              rounded
              hide-details=""
              v-model="selectedEmployee"
              :items="employees"
              label="Employee"
              @change="getData()"
            ></v-select>
            <v-row class="ma-0 mt-3">
              <v-col align="center" class="pa-0 mr-1">
                <v-select
                  solo
                  hide-details=""
                  dense
                  rounded
                  v-model="form.selectedMonth"
                  :items="months"
                  label="Month"
                  @change="generateMonthDays"
                ></v-select>
              </v-col>
              <v-col align="center" class="pa-0 ml-1">
                <v-select
                  solo
                  hide-details=""
                  dense
                  rounded
                  v-model="form.selectedYear"
                  :items="years"
                  label="Year"
                  @change="generateMonthDays"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <v-col align="center" v-if="user.roles[0].name == 'Client'">
            <v-select
              class="pa-0 mt-3"
              solo
              dense
              rounded
              hide-details=""
              v-model="selectedEmployee"
              :items="employees"
              label="Employee"
              @change="getData()"
            ></v-select>
          </v-col>

          <!-- Main Container -->
          <v-card
            class="mb-0 pb-0"
            rounded="t-xl"
            color="blue lighten-5"
            width="auto"
            min-height="430"
          >
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="ma-0" justify="center">
                <v-col class="pa-0 mx-2" align="center">
                  <v-slide-group
                    ref="slideGroup"
                    center-active
                    v-model="current_date"
                  >
                    <v-slide-item
                      v-for="(monthDay, index) in monthDays"
                      :key="index"
                      v-slot="{ active, toggle }"
                    >
                      <v-btn
                        class="mx-2"
                        height="55"
                        width="65"
                        :input-value="active"
                        :class="{
                          'blue white--text': active,
                          'grey lighten-2': !active,
                        }"
                        rounded
                        depressed
                        @click="toggle(), getData(monthDay.completeDate)"
                      >
                        {{ monthDay.date }}
                        <br />
                        {{ monthDay.day }}
                        <br />
                      </v-btn>
                    </v-slide-item>
                  </v-slide-group>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col>
                  <hr />
                  <h4 class="font-weight-bold mt-2">Today's Tasks</h4>
                </v-col>
              </v-row>
              <template
                v-if="
                  user_daily_tasks.length > 0 &&
                  (user.roles[0].name == 'Admin' ||
                    user.roles[0].name == 'Supervisor' ||
                    user.roles[0].name == 'Client')
                "
              >
                <v-row
                  class="ma-0"
                  v-for="(user_daily_task, index) in user_daily_tasks"
                  :key="index"
                >
                  <v-col class="pa-0" style="max-width: 70px">
                    <h5 class="my-4">
                      {{ user_daily_task.from_time }} <br />
                      To<br />
                      {{ user_daily_task.to_time }}
                    </h5>
                  </v-col>
                  <v-col class="pa-1">
                    <v-card rounded="xl" :color="getCardColor(user_daily_task)">
                      <v-row class="ma-1">
                        <v-col class="pa-0 my-2 mx-2">
                          <v-autocomplete
                            rounded
                            hide-details=""
                            ref="user"
                            v-model="user_daily_task.daily_task_id"
                            :items="dailyTasks"
                            label="Select Daily Tasks"
                            solo
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0">
                        <v-col class="pa-0 mb-2 mx-2">
                          <v-text-field
                            hide-details=""
                            v-model="user_daily_task.description"
                            label="Remark"
                            solo
                            dense
                            rounded
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                  <v-col class="pa-0" style="max-width: 40px" align="center">
                    <v-icon
                      class="mt-7"
                      dark
                      color="red"
                      @click="DeleteDaySummery(user_daily_task)"
                    >
                      mdi-delete
                    </v-icon>
                    <v-btn
                      @click="updateTask(user_daily_task.id, (is_done = 1))"
                      :color="
                        user_daily_task.is_done == 1
                          ? 'green'
                          : 'grey lighten-2'
                      "
                      class="mr-2"
                      x-small
                      dark
                      >Y</v-btn
                    >

                    <v-btn
                      @click="updateTask(user_daily_task.id, (is_done = 0))"
                      :color="
                        user_daily_task.is_done == 0 ? 'red' : 'grey lighten-2'
                      "
                      x-small
                      dark
                      >N</v-btn
                    >
                  </v-col>
                </v-row>
              </template>
              <template
                v-if="
                  user_daily_tasks.length > 0 &&
                  user.roles[0].name == 'Employee'
                "
              >
                <v-row
                  class="ma-0"
                  v-for="(user_daily_task, index) in user_daily_tasks"
                  :key="index"
                >
                  <v-col class="pa-0" style="max-width: 70px">
                    <h5 class="mt-2">
                      {{ user_daily_task.from_time }} <br />
                      To<br />
                      {{ user_daily_task.to_time }}
                    </h5>
                  </v-col>
                  <v-col class="pa-0 pl-2">
                    <v-card rounded="xl" :color="getCardColor(user_daily_task)">
                      <v-row class="ma-2">
                        <v-col class="pa-2">
                          <v-row>
                            <v-col style="min-width: 160px; min-height: 50px">
                              <span
                                class="blue--text subtitle-2 text--darken-2 font-weight-bold"
                              >
                                {{
                                  user_daily_task.daily_task
                                    ? user_daily_task.daily_task.code
                                    : ""
                                }}
                              </span>
                            </v-col>
                            <v-col
                              class="text-right pa-0 ma-0 mt-3 mr-4"
                              style="max-width: fit-content"
                            >
                              <span class="subtitle-2 text--darken-2">
                                Completed
                              </span>
                            </v-col>
                          </v-row>
                          <v-row class="ma-0">
                            <v-col style="min-width: 90px" class="pa-0">
                              <span class="grey--text caption font-weight-bold">
                                Remarks:
                                {{
                                  user_daily_task.description
                                    ? user_daily_task.description
                                    : ""
                                }}
                              </span>
                            </v-col>
                            <!-- <v-col class="pa-0" style="max-width: 50px">
                              <v-icon>mdi-microphone</v-icon>
                            </v-col> -->
                            <v-col
                              class="mx-0 pa-0"
                              style="max-width: fit-content"
                            >
                              <v-btn
                                @click="
                                  updateTask(user_daily_task.id, (is_done = 1))
                                "
                                :color="
                                  user_daily_task.is_done == 1
                                    ? 'green'
                                    : 'grey lighten-2'
                                "
                                class="mr-2"
                                x-small
                                dark
                                >Y</v-btn
                              >

                              <v-btn
                                @click="
                                  updateTask(user_daily_task.id, (is_done = 0))
                                "
                                :color="
                                  user_daily_task.is_done == 0
                                    ? 'red'
                                    : 'grey lighten-2'
                                "
                                x-small
                                dark
                                >N</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
              <template v-if="user_daily_tasks.length == 0">
                <v-row justify="center">
                  <v-col align="center" style="min-height: 100px">
                    <v-progress-circular
                      v-if="isLoading == true"
                      :size="70"
                      :width="7"
                      color="blue"
                      indeterminate
                    ></v-progress-circular>
                    <span v-else> No tasks for the date. </span>
                  </v-col>
                </v-row>
              </template>
            </v-container>
            <v-fab-transition
              v-if="
                (user.roles[0].name == 'Admin' ||
                  user.roles[0].name == 'Supervisor') &&
                user_daily_tasks.length == 0
              "
            >
              <v-btn
                color="pink"
                :to="`/daily-works/${selectedEmployee}/create?date=${selecteDate}`"
                dark
                absolute
                bottom
                right
                fab
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-fab-transition>
            <v-fab-transition
              v-if="
                (user.roles[0].name == 'Admin' ||
                  user.roles[0].name == 'Supervisor' ||
                  user.roles[0].name == 'Client') &&
                user_daily_tasks.length > 0
              "
            >
              <v-btn color="pink" @click="save" dark absolute bottom right fab>
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      isLoading: false,
      form: {},
      monthDays: [],
      user_daily_tasks: [],
      current_date: "",
      employees: [],
      selectedEmployee: "",
      selecteDate: "",
      dailyTasks: [],
      months: [
        { text: "January", value: "1" },
        { text: "February", value: "2" },
        { text: "March", value: "3" },
        { text: "April", value: "4" },
        { text: "May", value: "5" },
        { text: "June", value: "6" },
        { text: "July", value: "7" },
        { text: "August", value: "8" },
        { text: "September", value: "9" },
        { text: "October", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" },
      ],
      years: [
        { text: "2022", value: "2022" },
        { text: "2023", value: "2023" },
        { text: "2024", value: "2024" },
      ],
      form: {
        selectedMonth: new Date().getMonth() + 1,
        selectedYear: new Date().getFullYear(),
      },
      sites: [],
    };
  },

  mounted() {
    this.getMasters();
    if (this.user.roles[0].name == "Employee") {
      this.getData();
    }
    if (
      this.user.roles[0].name == "Admin" ||
      this.user.roles[0].name == "Supervisor" ||
      this.user.roles[0].name == "Client"
    ) {
      this.getAdminMasters();
    }
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    this.current_date = currentDay - 1;
    console.log(new Date().getMonth());
    this.generateMonthDays();
  },
  methods: {
    async getData(today = "") {
      this.isLoading = true;

      let date = today != "" ? today : moment().format("YYYY-M-D");
      let userId = "";
      this.selecteDate = date;
      if (this.user.roles[0].name == "Employee") {
        userId = this.user.id;
      } else {
        userId = this.selectedEmployee;
      }
      let user_daily_tasks = await axios.get(
        `users/${userId}/user_daily_summaries?userId=${userId}&date=${date}`
      );
      this.user_daily_tasks = user_daily_tasks.data.data;
      this.isLoading = false;
    },
    async getMasters() {
      let masters = await axios.get(
        `/monthly_salaries/masters?site_id=${this.site.id}`
      );
      masters = masters.data;

      masters.sites.forEach((site) => {
        this.sites.push({
          id: site.id,
          text: site.name,
          value: site.id,
        });
      });
      if (this.user.roles[0].name == "Client") {
        let employees = await axios.get(`/users?role_id=3`);
        employees = employees.data.data;
        employees.forEach((user) => {
          this.employees.push({
            id: user.id,
            text: user.full_name,
            value: user.id,
          });
        });
      }
    },

    getCardColor(user_daily_task) {
      console.log(user_daily_task);
      if (!user_daily_task.done_time) {
        return "white";
      }

      const doneTime = toDate(user_daily_task.done_time);
      const toTime = toDate(user_daily_task.to_time);

      // Function to convert "HH:MM AM/PM" to a Date object for comparison
      function toDate(timeStr) {
        // Check if timeStr is valid before proceeding
        if (!timeStr || typeof timeStr !== "string") {
          console.error("Invalid timeStr: ", timeStr);
          return null;
        }

        const [time, modifier] = timeStr.split(" ");
        let [hours, minutes] = time.split(":");

        if (hours === "12") {
          hours = modifier === "AM" ? "00" : "12";
        } else if (modifier === "PM") {
          hours = String(parseInt(hours, 10) + 12);
        }

        const date = new Date();
        date.setHours(parseInt(hours), parseInt(minutes), 0, 0);
        return date;
      }

      // Compare the times
      if (doneTime < toTime) {
        return "green"; // Task completed earlier than the expected time
      }
      if (doneTime >= toTime) {
        return "red"; // Task completed later than the expected time
      }
    },
    async getSiteWiseEmployees() {
      this.employees = [];
      let employees = await axios.get(`/users?site_id=${this.form.site_id}`);
      employees = employees.data.data;
      employees = employees.filter((employee) => employee.employee_id != null);
      employees.forEach((employee) => {
        this.employees.push({
          id: employee.id,
          text: employee.full_name,
          value: employee.id,
        });
      });

      // console.log(this.groups);
    },
    async getAdminMasters() {
      let masters = await axios.get("/user_daily_summaries/masters");
      masters = masters.data;

      let dailyTasks = masters.daily_tasks;
      dailyTasks.forEach((dailyTask) => {
        this.dailyTasks.push({
          id: dailyTask.id,
          text: dailyTask.code,
          value: dailyTask.id,
        });
      });
    },
    generateMonthDays() {
      this.monthDays = [];
      const now = new Date();

      const firstDayOfMonth = new Date(
        this.form.selectedYear,
        this.form.selectedMonth - 1,
        1
      );
      const lastDayOfMonth = new Date(
        this.form.selectedYear,
        this.form.selectedMonth,
        0
      );
      console.log("now Date");
      console.log(now);
      console.log("now.getMonth()");
      console.log(now.getMonth());
      console.log("firstDayOfMonth");
      console.log(firstDayOfMonth);
      console.log("lastDayOfMonth");
      console.log(lastDayOfMonth);

      for (
        let date = new Date(firstDayOfMonth);
        date <= lastDayOfMonth;
        date.setDate(date.getDate() + 1)
      ) {
        const dayObj = {
          day: this.getDayName(date.getDay()),
          date: date.getDate(),
          month: date.getMonth() + 1,
          year: date.getFullYear(),
          completeDate: this.formatCompleteDate(date),
        };

        this.monthDays.push(dayObj);
      }
    },

    getDayName(dayIndex) {
      const daysOfWeek = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
      return daysOfWeek[dayIndex];
    },
    formatCompleteDate(date) {
      const formattedDate = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
      return formattedDate;
    },
    async updateTask(taskId, is_completed) {
      this.isLoading = true;
      console.log(taskId);
      console.log(is_completed);
      this.form.is_done = is_completed;
      const currentTime = moment().format("h:mm A");
      this.form.done_time = currentTime;
      console.log(this.form);
      await axios.patch(
        `users/${this.user.id}/user_daily_summaries/${taskId}`,
        this.form
      );
      this.getData();
      this.isLoading = false;
    },
    async DeleteDaySummery(daysummery) {
      var r = confirm("Are you sure you want to delete the data!");
      if (r == true) {
        await axios.delete(
          `/users/${this.selectedEmployee}/user_daily_summaries/${daysummery.id}`
        );
        this.getData(daysummery.date);
      }
    },
    async save() {
      if (this.user_daily_tasks.length > 0) {
        this.isLoading = true;
        let payload = {
          datas: this.user_daily_tasks,
        };
        let response = await axios.post(
          `/users/${this.selectedEmployee}/user_daily_summaries_multiple`,
          payload
        );
        this.user_daily_tasks = response.data.data;
        this.isLoading = false;
      }
    },
  },
};
</script>
