<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0">
          <!-- Main Container -->
          <v-btn
            fab
            x-small
            class="mx-2 mb-2"
            color="white"
            @click="$router.back()"
          >
            <v-icon light> mdi-arrow-left </v-icon>
          </v-btn>
          <v-card class="mt-4" rounded="xl" color="blue lighten-5" width="auto">
            <!-- Dashboard Container -->
            <v-container>
              <v-row class="pt-4" justify="center">
                <v-col align="center">
                  <v-toolbar-title> Select your language: </v-toolbar-title>
                </v-col>
              </v-row>
              <v-row class="ma-0">
                <v-col class="pa-0 mx-3">
                  <v-select
                    solo
                    v-model="selectedCourseId"
                    dense
                    rounded
                    hide-details=""
                    :items="courseItems"
                    label="Select"
                    @change="getData(selectedCourseId)"
                  ></v-select>
                  <!-- @change="updateCalendar" -->
                </v-col>
              </v-row>

              <!-- Category Cards -->
              <template v-if="training_videos.length == 0">
                <v-row justify="center" class="ma-0 mt-12">
                  <v-col align="center" class="pa-0 my-12">
                    <span class="blue--text text-h5"
                      >Please select your language to view training videos</span
                    >
                  </v-col>
                </v-row>
                <v-row justify="center" class="ma-0">
                  <v-col align="center" class="pa-0 pb-0 mb-b">
                    <v-img
                      :src="
                        require('../../assets/voe_imgs/training-videos.png')
                      "
                      alt="Login Image"
                      max-width="220"
                    ></v-img>
                  </v-col>
                </v-row>
              </template>
              <template v-if="training_videos.length > 0">
                <v-row class="pb-5">
                  <v-col
                    md="6"
                    sm="6"
                    xs="6"
                    v-for="training_video in training_videos"
                    :key="training_video.id"
                  >
                    <v-card
                      outlined
                      height="138"
                      width="150"
                      rounded="xl"
                      elevation="10"
                      class="custom-border"
                    >
                      <iframe
                        height="136"
                        width="148"
                        :src="`https://www.youtube.com/embed/${training_video.videolink}`"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </v-card>
                    <v-col class="mt-2">
                      <v-row>
                        <v-col
                          md="8"
                          sm="8"
                          class="ma-0 pa-0"
                          style="min-width: 110px"
                          ><span class="subtitle-2">{{
                            training_video.title
                          }}</span></v-col
                        >
                        <v-col
                          md="4"
                          sm="4"
                          class="blue--text ma-0 pa-0"
                          align="left"
                          ><span class="subtitle-2">{{
                            training_video.no_of_hrs
                              ? training_video.no_of_hrs
                              : ""
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </template>
              <!-- <template v-if="training_videos.length == 0">
                <v-row justify="center" class="pb-6">
                  <v-col align="center">
                    <span> No Training Video Found.</span>
                  </v-col>
                </v-row>
              </template> -->
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container> -->
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      isLoading: false,
      training_videos: [],
      courseItems: [],
    };
  },
  mounted() {
    this.getMasters();
  },
  methods: {
    async getMasters() {
      let masters = await axios.get("courses");
      masters = masters.data.data;
      masters.forEach((course) => {
        this.courseItems.push({
          id: course.id,
          text: course.course_name,
          value: course.id,
        });
      });
    },
    async getData(courseId = "") {
      this.isLoading = true;
      let training_videos = await axios.get(
        `/courses/${courseId}/course_details`
      );
      this.training_videos = training_videos.data.data;
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.custom-border {
  border-color: #2b8fda; /* Replace with your desired border color */
}
</style>
