<template>
  <v-main>
    <v-container fluid>
      <v-btn
        fab
        x-small
        class="mx-2 mb-2"
        color="white"
        @click="$router.back()"
      >
        <v-icon light> mdi-arrow-left </v-icon>
      </v-btn>
      <v-row justify="center">
        <v-col md="8" lg="6" sm="6" class="px-0 pb-0">
          <!-- Main Container -->
          <v-card
            class="mt-4 mb-0 pb-0"
            rounded="xl"
            color="blue lighten-5"
            width="auto"
            min-height="480"
          >
            <v-row class="ma-0" justify="center">
              <v-col class="pa-0 mx-4 my-4">
                <h3>Permissions</h3>
              </v-col>
            </v-row>
            <template>
              <v-row class="ma-0">
                <v-col class="pa-0 mx-3" style="min-width: 200px">
                  <v-simple-table height="450px" fixed-header class="mb-4">
                    <thead>
                      <tr>
                        <th style="min-width: 55px">Sr. No.</th>
                        <th style="min-width: 85px">Id</th>
                        <th
                          v-for="(header, h) in headers"
                          :key="`header${h}`"
                          style="min-width: 212px"
                        >
                          {{ header.text }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in items" :key="`item${i}`">
                        <td>{{ i + 1 }}</td>
                        <td>PER{{ item.id }}</td>
                        <td>{{ item.name }}</td>
                        <td v-for="(header, i) in headers.slice(1)" :key="i">
                          <div class="form-check">
                            <input
                              type="checkbox"
                              hide-details
                              v-model="selected"
                              :value="item.id + '' + header.value"
                              @click="updatePermission(item.id, header.value)"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-col>
              </v-row>
            </template>
            <!-- <template v-if="course_details.length == 0">
              <v-row justify="center">
                <v-col align="center" style="min-height: 100px">
                  <v-progress-circular
                    v-if="isLoading == true"
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                  <span v-else> No training video details found. </span>
                </v-col>
              </v-row>
            </template> -->
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="footer-text">
      <v-row justify="center">
        <v-col align="center">
          <h3 class="white--text">We Alliance Sparkle</h3>
          <h4 class="white--text">v 1.0.0</h4>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  name: "ManagePermissions",
  data: () => ({
    headers: [{ text: "Permissions", value: "permissions" }],
    items: [],
    loading: true,
    selected: [], //This array will define the vmodel of checkbox,
  }),
  async mounted() {
    let permissions = await axios.get("/permissions");
    let roles = await axios.get("/roles?search=all");
    this.permissions = permissions.data.data.filter(
      (p) => (p.id != 1) & (p.id != 2) & (p.id != 3) & (p.id != 4) & (p.id != 5)
    );
    this.roles = roles.data.data;

    this.roles = this.restrictRolesList(this.roles);
    this.roles.forEach((role) => {
      this.headers.push({
        text: role.name,
        value: role.id,
      });
    });
    this.updateSelected(this.roles);
    // this.permissions = this.restrictPermissions(this.permissions)
    this.permissions.forEach((item) => {
      this.items.push({
        id: item.id,
        name: item.name,
      });
    });
    this.loading = false;
  },
  methods: {
    async updatePermission(rowId, columnId) {
      // rowId is permission and columnId is role
      // Assign or unassign permission to role
      let permission_payload = {
        role_id: columnId,
        permission_id: rowId,
      };
      if (this.selected.indexOf(rowId + "" + columnId) == -1)
        await axios.post("/assign_permissions", permission_payload);
      else await axios.post("/unassign_permissions", permission_payload);
      this.updateSelected();
    },
    async updateSelected() {
      let roles = await axios.get("/roles?search=all");
      this.roles = roles.data.data;
      this.roles = this.restrictRolesList(this.roles);
      this.selected = [];
      roles.data.data.forEach((role) => {
        role.permissions.forEach((per) => {
          this.selected.push(per.id + "" + role.id);
        });
      });
    },
    restrictRolesList(roles) {
      roles = roles.filter((role) => role.name != "Super Admin");
      // roles = roles.filter(role => role.name != 'Admin')
      return roles;
    },
    restrictPermissions(permissions) {
      permissions = permissions.filter(
        (permission) => permission.name != "Manage Organizations"
      );
      permissions = permissions.filter(
        (permission) => permission.name != "Super Admin Settings"
      );
      return permissions;
    },
  },
};
</script>
